import { useForm, ValidationError } from "@formspree/react";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function ContactForm() {
	const [state, handleSubmit] = useForm("mvoeleqb");

	if (state.succeeded) {
		return (
			<div>
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label className="montserrat-bold">First name</Form.Label>
								<Form.Control
									name="name"
									type="text"
									style={{ height: "48px" }}
									required
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="surname">
								<Form.Label className="montserrat-bold">Surname</Form.Label>
								<Form.Control
									name="surname"
									type="text"
									style={{ height: "48px" }}
									required
								/>
							</Form.Group>
							<ValidationError
								prefix="Surname"
								field="surname"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="jobTitle">
								<Form.Label className="montserrat-bold">Job Title</Form.Label>
								<Form.Control
									type="text"
									name="jobTitle"
									style={{ height: "48px" }}
									required
								/>
							</Form.Group>

							<ValidationError
								prefix="JobTitle"
								field="jobTitle"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="company">
								<Form.Label className="montserrat-bold">Company</Form.Label>
								<Form.Control
									name="company"
									type="text"
									style={{ height: "48px" }}
									required
								/>
							</Form.Group>

							<ValidationError
								prefix="Company"
								field="company"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="telephone">
								<Form.Label className="montserrat-bold">
									Email address
								</Form.Label>
								<Form.Control
									type="email"
									name="email"
									style={{ height: "48px" }}
									required
								/>
							</Form.Group>

							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="my-3">
								<span className="montserrat-bold fs-6">Location:</span>
								<Form.Check
									inline
									label="UK"
									name="location"
									type={"radio"}
									id={`inline-radio-1`}
									className="mx-4"
									value="UK"
								/>
								<Form.Check
									inline
									label="Elsewhere in the world"
									name="location"
									type="radio"
									id={`inline-radio-2`}
									value="Elsewhere in the world"
								/>
							</div>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<Form.Group className="mb-3" controlId="message">
								<Form.Label className="montserrat-bold">Message</Form.Label>
								<Form.Control
									as="textarea"
									name="message"
									placeholder="Type your Message"
									rows={4}
								/>
							</Form.Group>

							<ValidationError
								prefix="message"
								field="message"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="mt-3">
								<Form.Check
									inline
									label="I accept the Terms"
									name="acceptTerms"
									type={"checkbox"}
									required
									value="True"
								/>
							</div>
						</Col>
					</Row>

					<Button
						size="small"
						className="px-4 py-3 mt-5 montserrat-bold fs-6 btn btn-secondary w-100 w-md-auto"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Submit
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="montserrat-bold">First name</Form.Label>
						<Form.Control
							name="name"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="surname">
						<Form.Label className="montserrat-bold">Surname</Form.Label>
						<Form.Control
							name="surname"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError
						prefix="Surname"
						field="surname"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="jobTitle">
						<Form.Label className="montserrat-bold">Job Title</Form.Label>
						<Form.Control
							type="text"
							name="jobTitle"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="JobTitle"
						field="jobTitle"
						errors={state.errors}
					/>
				</Col>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="company">
						<Form.Label className="montserrat-bold">Company</Form.Label>
						<Form.Control
							name="company"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="montserrat-bold">Email address</Form.Label>
						<Form.Control
							type="email"
							name="email"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="my-3">
						<span className="montserrat-bold fs-6">Location:</span>
						<Form.Check
							inline
							label="UK"
							name="location"
							type={"radio"}
							id={`inline-radio-1`}
							className="mx-4"
							value="UK"
						/>
						<Form.Check
							inline
							label="Elsewhere in the world"
							name="location"
							type="radio"
							id={`inline-radio-2`}
							value="Elsewhere in the world"
						/>
					</div>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Form.Group className="mb-3" controlId="message">
						<Form.Label className="montserrat-bold">Message</Form.Label>
						<Form.Control
							as="textarea"
							name="message"
							placeholder="Type your Message"
							rows={4}
						/>
					</Form.Group>

					<ValidationError
						prefix="message"
						field="message"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="mt-3">
						<Form.Check
							inline
							label="I accept the Terms"
							name="acceptTerms"
							type={"checkbox"}
							required
							value="True"
						/>
					</div>
				</Col>
			</Row>

			<Button
				size="small"
				className="px-4 py-3 mt-5 montserrat-bold fs-6 btn btn-secondary w-100 w-md-auto"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
		</Form>
	);
}
export default ContactForm;
